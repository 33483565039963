// @ts-check

import * as FullStory from '@fullstory/browser';
import config from 'config';
import * as authType from 'constants/action-types';
import { PORTAL } from 'constants/heap-events';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { setSignInCohortData } from 'redux/actions/sign-in';
import axios from 'util/api';
import { issueAuth0SpaPasswordLogin } from 'util/auth/auth0-password-flow-spa.utils';
import { AUTH_FLOW_PASSWORDLESS_ENABLED } from 'util/auth/auth0.constants';
import { logAuthError } from 'util/auth/auth0.utils';

// To be removed in scope <https://myhippo.atlassian.net/browse/FRC-5626>
/**
 * @param {Object} data
 * @param {Object} data.payload
 * @param {string} data.payload.email
 *
 * @throws {Error} If an error occurs during the process.
 */
function* checkIsUserPasswordless({ payload }) {
  try {
    const email = payload.email;

    const res = yield call(
      axios.get,
      `${
        // @ts-expect-error
        config.BFF_URL
      }/api/v1/producer/auth0-cohort?email=${encodeURIComponent(payload.email)}`
    );

    const cohort = res.data?.cohort;
    const unsafeProducerCode = res.data?.producer_code;
    const isVerified = res.data?.isVerified;
    const isPasswordless = isInPasswordlessCohort(cohort);

    yield put(
      setSignInCohortData({
        email,
        unsafeProducerCode,
        isVerified,
      })
    );

    yield call(handleSignInStepTransitionTracking, {
      unsafeProducerCode,
      isPasswordless,
    });

    if (isPasswordless) {
      // persist the flag to resolve correct client in both apps
      localStorage.setItem(AUTH_FLOW_PASSWORDLESS_ENABLED, 'true');
      return;
    }

    localStorage.setItem(AUTH_FLOW_PASSWORDLESS_ENABLED, 'false');
    yield issueAuth0SpaPasswordLogin({
      email: String(payload.email),
      producerCode: unsafeProducerCode,
    });
  } catch (err) {
    logAuthError(err);
    // in case of error, redirect to Refresh Token Password Flow
    yield issueAuth0SpaPasswordLogin({
      email: String(payload.email),
    });
  }
}

function* checkIsUserPasswordlessWatcher() {
  // @ts-expect-error
  yield takeEvery(authType.CHECK_IS_PASSWORDLESS, checkIsUserPasswordless);
}

export function isInPasswordlessCohort(cohort) {
  return cohort === 'passwordless';
}

/**
 * Handles the transition tracking for the sign-in step.
 *
 * @param {Object} options - The options for the transition tracking.
 * @param {string} options.unsafeProducerCode - The unsafe producer code.
 * @param {boolean} options.isPasswordless - Indicates if the sign-in is passwordless.
 */
export function handleSignInStepTransitionTracking({
  unsafeProducerCode,
  isPasswordless,
}) {
  // Track producer identity for FullStory
  // Unsafe code is not an issue for FullStory, but this may break Heap tracking, therefore it's not implemented here
  FullStory.setUserVars({ producerCode: unsafeProducerCode });

  // Track which flow user is going to view
  window.heap.track(PORTAL.AUTH_FLOW_VIEW_LOGIN, {
    'Login type': isPasswordless ? 'pwdless' : 'pwd',
    // "is passwordless check" (email -> email+password/passwordless transition) does not occur with auto logout
    'Auto logout': false,
  });
}

export default function* rootSaga() {
  yield all([fork(checkIsUserPasswordlessWatcher)]);
}
