export const APPLICATION = {
  DETAILS_PAGE_CLICK_SUBMIT: 'Application - Details Page - Click - Submit',
  LICENSE_PAGE_AGENCY_NPN_CLICK:
    'Application - License Page - Agency NPN - Click',
  LICENSE_PAGE_ENTER: 'Application - License Page - Enter',
  LICENSE_PAGE_OWNER_NPN_CLICK:
    'Application - License Page - Owner NPN - Click',
  TAX_PAGE_CLICK_SUBMIT: 'Application - Tax Page - Click - Submit',
  TAX_PAGE_UPLOAD_STATUS_W9: 'Application - Tax Page - Upload Status - W9',
  VIEW_AGREEMENT_PAGE: 'Application - View - Agreement Page',
  VIEW_DETAILS_PAGE: 'Application - View - Details Page',
  VIEW_EO_PAGE: 'Application - View - EO Page',
  VIEW_LICENSE_PAGE: 'Application - View - License Page',
  VIEW_PROFILE_PAGE: 'Application - View - Profile Page',
  VIEW_TAX_PAGE: 'Application - View - Tax Page',
  VIEW_WELCOME_PAGE: 'Application - View - Welcome Page',
  VIEW_SUCCESS_PAGE: 'Application - View - Success Page',
  VIEW_SWITCH_TO_DESKTOP: 'Application - View - Switch to Desktop Page',
};

export const PORTAL = {
  CROSS_SELL_VIEW: 'Portal - Cross Sell - View',
  CROSS_SELL_BACK_CLICK: 'Portal - Cross Sell - Back - Click',
  CROSS_SELL_CHOOSE_PRODUCT_CLICK:
    'Portal - Cross Sell - Choose Product - Click',
  CARRIER_PORTAL_CLICK_CARRIER_OUTLINK:
    'Portal - Carrier Portals - Click - Carrier Outlink',
  MISSING_BANK_CANCEL_CLICK: 'Portal - Missing Bank Details - Cancel - Click',
  MISSING_BANK_DETAILS_VIEW: 'Portal - Missing Bank Details - View',
  MISSING_BANK_ENTER_DETAILS:
    'Portal - Missing Bank Details - Enter Details - Click',
  QUOTE_CONNECTOR_CLICK_EXISTING_QUOTE:
    'Portal - Quote Connector - Click - Existing Quote',
  QUOTE_CONNECTOR_CLICK_NEW_QUOTE:
    'Portal - Quote Connector - Click - New Quote',
  QUOTE_CONNECTOR_CREDENTIALS_ADD_STATE:
    'Portal - Quote Connector - Credentials - Add State credentials - Click',
  QUOTE_CONNECTOR_CREDENTIALS_HELP_CLICK:
    'Portal - Quote Connector - Credentials - Help - Click',
  QUOTE_CONNECTOR_CREDENTIALS_NEXT_CLICK:
    'Portal - Quote Connector - Credentials - Next - Click',
  QUOTE_CONNECTOR_CREDENTIALS_SKIP_CLICK:
    'Portal - Quote Connector - Credentials - Skip - Click',
  QUOTE_CONNECTOR_CREDENTIALS_START_QUOTING_CLICK:
    'Portal - Quote Connector - Credentials - Start Quoting - Click',
  QUOTE_CONNECTOR_CREDENTIALS_VIEW:
    'Portal - Quote Connector - Credentials - View',
  QUOTE_CONNECTOR_GET_STARTED_CLICK:
    'Portal - Quote Connector - Get Started - Click',
  QUOTE_CONNECTOR_PRODUCT_SELECT_CLICK:
    'Portal - Quote Connector - Product Select - Click - ',
  QUOTE_CONNECTOR_PRODUCT_SELECT_SUBMIT:
    'Portal - Quote Connector - Product Select - Submit',
  QUOTE_CONNECTOR_VIEW_PRODUCT_SELECT:
    'Portal - Quote Connector - View - Product Select',
  TEXT_CONSENT_ARE_YOU_SURE_CLICK_I_AM_IN:
    "Portal - Text consent modal - Are you sure - Click - I'm in",
  TEXT_CONSENT_ARE_YOU_SURE_CLICK_NO_THANKS:
    'Portal - Text consent modal - Are you sure - Click - No thanks',
  TEXT_CONSENT_ARE_YOU_SURE_VIEW:
    'Portal - Text consent modal - Are you sure - View',
  TEXT_CONSENT_CLICK_CONFIRM: 'Portal - Text consent modal - Click - Confirm',
  TEXT_CONSENT_CLICK_NO_THANKS:
    'Portal - Text consent modal - Click - No thanks',
  TEXT_CONSENT_MODAL_VIEW: 'Portal - Text consent modal - View',
  STORE_MODAL_VIEW: 'Portal - View - Store Modal',
  STORE_MODAL_CLICK: 'Portal - Click - Store Modal',
  TOPBAR_TOOLTIP_OUTLINK_CLICK:
    'Portal - Carrier Portals - Click - Carrier Outlink',
  CLICK_NEW_CARRIER: 'Portal - Carrier Portal - Click - New Carrier',
  CLICK_REPORT_BIND: 'Portal - Fast Start - Click - Report Bind',
  VIEW_FAST_START: 'Portal - View - Fast Start',
  DASHBOARD_FAST_START_VIEW: 'Portal - Dashboard - Fast Start - View',
  DASHBOARD_FAST_START_CLICK_REPORT_NOW:
    'Portal - Dashboard - Fast Start - Click - Report Now',
  NEW_CARRIER_VIEW: 'Portal – Carrier Portal – View – New Carrier',
  DASHBOARD_CLICK_Q2_REPORT_BIND:
    'Portal - Q2 Incentive - Click - Report Bind ',
  VIEW_Q2_INCENTIVE: 'Portal - View - Q2 Incentive',
  DASHBOARD_Q2_INCENTIVE_VIEW:
    'Portal - Dashboard Banner - Q2 Incentive - View',
  DASHBOARD_Q2_INCENTIVE_CLICK_REPORT_NOW:
    'Portal - Dashboard Banner - Q2 Incentive - Click - Report Now',
  MODAL_Q2_INCENTIVE_CLICK_REPORT_NOW:
    'Portal - Modal - Q2 Incentive - Click - Report Now',
  Q2_INCENTIVE_MODAL_VIEW: 'Portal - Q2 Incentive Modal - View',
  CASH_CONNECT_VIEW_LEADERBOARD: 'Portal - Cash Connect – View – Leaderboard',
  DASHBOARD_Q3_INCENTIVE_VIEW:
    'Portal - Dashboard Banner - Q3 Incentive - View',
  DASHBOARD_Q3_INCENTIVE_CLICK_REPORT_NOW:
    'Portal - Dashboard Banner - Q3 Incentive - Click - Report Now',
  DASHBOARD_Q4_INCENTIVE_CLICK_REPORT_NOW:
    'Portal - Dashboard Banner - Q4 Incentive - Click - Report Now',
  DASHBOARD_Q4_INCENTIVE_VIEW:
    'Portal - Dashboard Banner - Q4 Incentive - View',
  PORTAL_HELP_CENTER_CLICK: 'Portal – Click – Help Center',
  PORTAL_LEAD_FEED_CLICK: 'Portal - Nav - Click - Lead Feed',
  PORTAL_EXISTING_COVERAGE_CLICK: 'Portal - Nav - Click - Existing Coverage',
  PORTAL_DASHBOARD_VIEW_CARRIER_BAR: 'Portal – Dashboard – View – Carrier Bar',
  PORTAL_DASHBOARD_VIEW_QUOTE_CONNECTOR:
    'Portal – Dashboard – View – Quote Connector',
  PORTAL_DASHBOARD_VIEW_UPCOMING_RENEWALS:
    'Portal – Dashboard – View – Upcoming Renewals',
  MY_COMMISSION_PAGE_BANNER_VIEW:
    'Portal - View - My Commission Page - Banner - View',
  MY_COMMISSION_PAGE_BANNER_CLICK:
    'Portal - View - My Commission Page - Banner - Click',
  AUTH_FLOW_VIEW_LOGIN: 'Portal - View - Login',
  AUTH_FLOW_VIEW_LOGIN_PAGE: 'Portal - View - Login Page',
  AUTH_FLOW_LOGIN_GET_CODE_CLICK: 'Portal - Login - Click - Get Code',
  VIEW_MODAL: 'Portal - View - Modal',
  MODAL_CLICK_MAIN_CTA: 'Portal - Modal - Click - main CTA',
  MODAL_CLICK_SECONDARY_CTA: 'Portal - Modal - Click - secondary CTA',
};

export const FAST_START_BANNER = {
  NORMAL: 'Portal – View – Fast Start Banner – Normal',
  URGENT: 'Portal – View – Fast Start Banner – Urgent',
};

export const FAST_START_MODAL = {
  FIRST_APPOINTMENT: 'Portal – View – Fast Start Modal – First Time',
  NORMAL: 'Portal – View – Fast Start Modal – Normal',
  URGENT: 'Portal – View – Fast Start Modal – Urgent',
};
export const NEW_QUOTE_HEAP_ATTRIBUTE = {
  TOP: 'top',
  QC_MODULE: 'QC module',
};

export const ADD_TEAM_MEMBER_MODAL = {
  VIEW: 'Portal – Team Member – View – Add Team Member Modal',
  CLICK_ROLE: 'Portal – Team Member – Click – Role',
  CLICK_CTA: 'Portal – Team Member – Click – Add Team Member',
};

export const NOTIFICATION_CENTER = {
  CLICK: 'Portal - Click – Notification Center',
};

export const FLAGSHIP = {
  EXPERIMENT_SEEN: 'Flagship Experiment Seen'
}
