import axios from 'axios';
import { put } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { setModulePermissions } from '../redux/actions/module-permissions';
import { AUTHORIZATION, IMPERSONATED, POST, PUT } from 'constants/constant';
import config from 'config';
import { getToken } from './auth/get-token';
import { isValidAuthActivity, storeLastActivityTimestamp } from './auth/use-signout-on-inactivity';

export const download = (returnData) =>
  axios
    .get(returnData.signedRequest, { responseType: 'blob' })
    .then((resp) => resp.data)
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      // for ie and edge
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        const returnDecodedUrl = decodeURIComponent(returnData.url);
        var fileName = returnDecodedUrl.substr(
          returnDecodedUrl.lastIndexOf('/') + 1
        );

        window.navigator.msSaveOrOpenBlob(blob, fileName);
        return;
      }

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      const returnedDecodedUrl = decodeURIComponent(returnData.url);
      a.download = returnedDecodedUrl.substr(
        returnedDecodedUrl.lastIndexOf('/') + 1
      );
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    // eslint-disable-next-line
    .catch(() => console.log('error'));

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    ...(!isEmpty(localStorage.getItem('token')) && {
      'access-token': localStorage.getItem('token'),
    }),
  };
};

const getEnhancedHeaders = async (originalHeaders) => {
  let headers = { ...originalHeaders };

  const token = await getToken();

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  return headers;
};

const axiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: getHeaders(),
});

// Add endpoints which does not require impersonated in POST method.
const onlyAdminsUrl = [
  'api/v3/portal-settings/admin-message',
  'api/v3/admin/me',
  `${config.BFF_URL}/api/v1/producer/refetch-licenses`,
];

const stripBearerTokenForS3Request = (headers) => {
  delete headers.common.Authorization;
};
const isRequestToS3 = (url) => {
  return url.includes('.amazonaws.com');
};

// eslint-disable-next-line
axiosInstance.interceptors.request.use(async (config) => {
  const isAdminUrl = onlyAdminsUrl.includes(config.url);
  if (config.method === PUT) {
    // remove auth header for file upload put call only
    let headers = { ...config.headers };
    config.removeToken && delete headers.common[AUTHORIZATION];
    config.headers = { ...headers };
  }
  if (config.method === POST && !isAdminUrl) {
    // Add impersonated value
    let impersonated = localStorage.getItem('impersonateData');
    config.data[IMPERSONATED] = !!impersonated ? true : false;
  }
  if (isRequestToS3(config.url)) {
    stripBearerTokenForS3Request(config.headers);
  } else {
    config.headers = await getEnhancedHeaders(config.headers);
    if (isValidAuthActivity(config.url)) {
      // Auth0 treats activity as interaction with auth server - equate activity to API calls
      storeLastActivityTimestamp();
    }
  }
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    let token = response?.data?.token;
    if (!!token) {
      localStorage.setItem(AUTHORIZATION, token);
      let permissionDetails = JSON.parse(atob(token.split('.')[1])); // Decodes received permission token
      put(setModulePermissions(permissionDetails.permissions));
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
