import * as actions from '../../constants/action-types';

export const toggleCarrierAppointmentSettings = payload => ({
  type: actions.TOGGLE_CARRIER_APPOINTMENT_SETTINGS,
  payload,
});

export const updateCarrierAppointmentSettings = payload => ({
  type: actions.UPDATE_CARRIER_APPOINTMENT_SETTINGS,
  payload,
});

export const setAppointmentEnabled = payload => ({
  type: actions.SET_APPOINTMENT_ENABLED,
  payload,
});

export const setshowInStore = payload => ({
  type: actions.SET_SHOW_IN_STORE_ENABLED,
  payload,
});

export const openAppointmentSetting = () => ({
  type: actions.OPEN_APPOINTMENT_SETTING,
});

export const initDataCarrierAppointments = payload => ({
  type: actions.INIT_DATA_CARRIER_APPOINTMENTS,
  payload,
});

export const saveAppointmentSettings = payload => ({
  type: actions.SAVE_APPOINTMENT_SETTINGS,
  payload,
});

export const setUpdateAppointmentSettingLoader = payload => ({
  type: actions.SET_UPDATE_APPOINTMENT_SETTING_LOADER,
  payload,
});
